
    $('.customers-testimonials').owlCarousel({
        loop: true,
        center: true,
        nav:false,
        items: 1,
        dots:false,
        margin: 10,
        autoPlay: true,
        autoPlayTimeout: 1000,
        smartSpeed: 500,
        // responsive: {
        //     0: {
        //         items: 1
        //     },
        //     768:
        //         {
        //             items:2
        //         },
        //     1440:
        //         {
        //             items:3
        //         }
        //
        // }
    });

///

