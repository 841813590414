// (function($){
//     $('#thumbcarousel').carousel(0);
//     var $thumbItems = $('#thumbcarousel .item');
//     $('#carousel').on('slide.bs.carousel', function (event) {
//         var $slide = $(event.relatedTarget);
//         var thumbIndex = $slide.data('thumb');
//         var curThumbIndex = $thumbItems.index($('#thumbcarousel .item.active').get(0));
//         if (curThumbIndex>thumbIndex) {
//             $('#thumbcarousel').one('slid.bs.carousel', function (event) {
//                 $('#thumbcarousel').carousel(thumbIndex);
//             });
//             if (curThumbIndex === ($thumbItems.length-1)) {
//                 $('#thumbcarousel').carousel('next');
//             } else {
//                 $('#thumbcarousel').carousel(numThumbItems-1);
//             }
//         } else {
//             $('#thumbcarousel').carousel(thumbIndex);
//         }
//     });
// })(jQuery);
//
//
//
//
