
//deal of thE DAY
$('.dealofday-carousel, .categories').owlCarousel({
    autoPlay:3000,
    lazyLoad:true,
    nav:true,
    loop:false,
    margin:15,
    navRewind:false,
    smartSpeed:1000,
    navText : ["<i class='fa fa-chevron-left fa-1x'></i>","<i class='fa fa-chevron-right fa-1x'></i>"],
    dots:false,
    responsive:{
        0:{
            items:1
        },
        300:{
            items:2
        },
        425:{
            items:2
        },
        // 557:{
        //     items:3
        // },
        768:{
            items:3
        },
       830:{
          items:4
        },
        1024:{
            items:5
        },
        1328:{
            items:6
        }

    }
});


//jquery for the hide and show the main menu

$(function() {
    //caches a jQuery object containing the header element
    var header = $(".main_dropdown");
    var currPath = window.location.pathname; // Gets the current pathname ( /_display/ )
    var toggle_bottom =  $('#bottom__nav');

    if(currPath == '/index.html'){ // Checks if the pathname equals the webpage you want the sidebar to be displayed on
        header.show(); // Set the sidebar to visibility: visible and display: block
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 450 ) {
                header.hide();
                toggle_bottom.fadeIn(500);
                header.addClass('from_top');
            } else {
                header.show();
                toggle_bottom.fadeOut(500);
                header.removeClass('from_top');
            }
        });
    }else {
        header.hide();
        toggle_bottom.show();
        header.addClass('from_top');
    }


    $(this).scrollTop(0);

    // header.hide();
    $('#toggle_dd').click(function(){
        $(".main_dropdown").toggle();
    });

});
// mobile screen search box script//
$('.search_toggler').click(function(){

    $('.hidden-search').fadeIn(500);

});
$('.close_search').click(function(){

    $('.hidden-search').fadeOut(300);

});

$(function(){
    var header = $(".main_dropdown");

});

// script for the show filter
$('#btn-filter').click(function() {
    $(".left--side").addClass("show_filter");
    $('.cat-overlay').show();
    $('html').addClass("overflow_xy");
    // $(".category-filter").append('<div class="overlay" id="click_overlay" style="opacity: .4;top:0"></div>');
});
$('.cat-overlay').click(function(){
    console.log('click');
    $(this).hide();
    $(".left--side").removeClass('show_filter');
    $('html').removeClass("overflow_xy");
});
$('.done-filter').click(function(){
    $(".left--side").removeClass('show_filter');
    $('.cat-overlay').hide();
    $('html').removeClass("overflow_xy");
});


//script for the check out page

//jQuery time
var current_fs, next_fs, previous_fs; //fieldsets
var left, opacity, scale; //fieldset properties which we will animate
var animating; //flag to prevent quick multi-click glitches

$(".next").click(function(){
    // if(animating) return false;
    // animating = true;
    // current_fs = $(this).parent().parent().parent().parent().parent();
    // next_fs = $(this).parent().parent().parent().parent().parent().next();
    current_fs = $(this).parents('fieldset');
    next_fs=$(this).parents('fieldset').next();

    //activate next step on progressbar using the index of next_fs
    $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("actively");

    //show the next fieldset
    // next_fs.show();
    next_fs.delay(100).fadeIn(800);
    current_fs.hide();
    $(window).scrollTop(0);

});

$(".submit").click(function(){
    return false;
});

$('.terms-and-conditions-link').on('click',function () {
    $('.termscondition').fadeToggle();
});





// // javascript for time laps
// var second = 1000,
//     minute = second * 60,
//     hour = minute * 60,
//     day = hour * 24;
//
// var countDown = new Date('july 7, 2019 00:00:00').getTime(),
//     x = setInterval(function() {
//
//         var now = new Date().getTime(),
//             distance = countDown - now;
//         // console.log(distance,now);
//         // document.getElementById('days').innerText = Math.floor(distance / (day));
//         document.getElementById('hours').innerText = Math.floor((distance % (day)) / (hour));
//         document.getElementById('minutes').innerText = Math.floor((distance % (hour)) / (minute));
//         document.getElementById('seconds').innerText = Math.floor((distance % (minute)) / second);
//
//     }, second);
//


$('.feature_brand_list ').owlCarousel({
    items:1,
    margin:10,
    pagination: false,
    autoplay: true,
    autoPlaySpeed: 5000,
    autoPlayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed:1000,
    nav:false,
    lazyLoad:true,
    loop:false,
    navRewind:false,
    navText : ["<i class='fa fa-chevron-left fa-1x'></i>","<i class='fa fa-chevron-right fa-1x'></i>"],
    dots:true,
    responsive:{
        0:{
            items:1
        },
        320:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        },
        1328:{
            items:4
        },
        1400:{
            items:4
        }
    }
});


$('.col-three ').owlCarousel({
    items:1,
    margin:10,
    pagination: false,
    smartSpeed:1000,
    nav:false,
    lazyLoad:true,
    loop:false,
    navRewind:false,
    dots:true,
    autoplay: true,
    autoPlaySpeed: 5000,
    autoPlayTimeout: 5000,
    autoplayHoverPause: true,
    responsive:{
        0:{
            items:1
        },
        320:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        },
        1328:{
            items:3
        },
        1400:{
            items:3
        }
    }
});

//jQuery for hide and show the comments in review section
$(function() {
    $('article.reviews').slice(0,3).show();
    $('.show-more').on('click',function(){
        $('article:hidden').slice(0,3).slideDown();
        if($('article:hidden').length === 0){
            $('.show-more').fadeOut('slow');
        }
    });
});


//jquery for the price range slider
$( function() {
    $( "#slider-range" ).slider({
        range: true,
        min: 100,
        max: 5000,
        values: [ 100, 1000 ],
        slide: function( event, ui ) {
            $( "#amount" ).val( "Min : Rs" + ui.values[ 0 ] + " - Max : Rs" + ui.values[ 1 ] );
        }
    });
    $( "#amount" ).val( "Min : Rs" + $( "#slider-range" ).slider( "values", 0 ) +
        " - Max : Rs" + $( "#slider-range" ).slider( "values", 1 ) );
});

//jquer for the cart update

$(document).ready(function() {

    /* Set rates + misc */
    var taxRate = 0.15;
    var shippingRate = 15.00;
    var fadeTime = 300;


    /* Assign actions */
    $('.product-quantity input').change( function() {
        updateQuantity(this);
    });

    $('.product-removal button').click( function() {
        removeItem(this);
    });


    /* Recalculate cart */
    function recalculateCart()
    {
        var subtotal = 0;

        /* Sum up row totals */
        $('.product').each(function () {
            subtotal += parseFloat($(this).children('.product-line-price').text());
        });

        /* Calculate totals */
        var tax = subtotal * taxRate;
        var shipping = (subtotal > 0 ? shippingRate : 0);
        var total = subtotal + tax + shipping;

        /* Update totals display */
        $('.totals-value').fadeOut(fadeTime, function() {
            $('#cart-subtotal').html(subtotal.toFixed(2));
            $('#cart-tax').html(tax.toFixed(2));
            $('#cart-shipping').html(shipping.toFixed(2));
            $('#cart-total').html(total.toFixed(2));
            if(total == 0){
                $('.checkout').fadeOut(fadeTime);
            }else{
                $('.checkout').fadeIn(fadeTime);
            }
            $('.totals-value').fadeIn(fadeTime);
        });
    }


    /* Update quantity */
    function updateQuantity(quantityInput)
    {
        /* Calculate line price */
        var productRow = $(quantityInput).parent().parent();
        var price = parseFloat(productRow.children('.product-price').text());
        var quantity = $(quantityInput).val();
        var linePrice = price * quantity;

        /* Update line price display and recalc cart totals */
        productRow.children('.product-line-price').each(function () {
            $(this).fadeOut(fadeTime, function() {
                $(this).text(linePrice.toFixed(2));
                recalculateCart();
                $(this).fadeIn(fadeTime);
            });
        });
    }


    /* Remove item from cart */
    function removeItem(removeButton)
    {
        /* Remove row from DOM and recalc cart total */
        var productRow = $(removeButton).parent().parent();
        productRow.slideUp(fadeTime, function() {
            productRow.remove();
            recalculateCart();
        });
    }

});
// login form change
$(function() {

    $('#login-form-link').click(function(e) {
        $("#login-form, .form-title-in").delay(100).fadeIn(100);
        $("#register-form, .form-title-up").fadeOut(100);
        $('#register-form-link').removeClass('active');
        $(this).addClass('active');
        e.preventDefault();
    });
    $('#register-form-link').click(function(e) {
        $("#register-form , .form-title-up").delay(100).fadeIn(100);
        $("#login-form, .form-title-in").fadeOut(100);
        $('#login-form-link').removeClass('active');
        $(this).addClass('active');
        e.preventDefault();
    });

});
//jquery for mobile menu
jQuery('#menu').metisMenu().show();



//vendor registeration script

$('#vendor-continue').click(function(){
    $(this).hide();
    $('.detailForm').show(300);
    $('.vendorsignup').hide();
});
$('.returning-vendor').click(function(){
    $('.vendorsignup,#vendor-continue').show(300);
    $(".detailForm").hide();
});


$(function () {
    $('.option_yes_VAT').hide();

    //show it when the checkbox is clicked
    $('input[name="checkbox"]').on('click', function () {
        if ($(this).prop('checked')) {
            $('.option_yes_VAT').fadeIn();
        } else {
            $('.option_yes_VAT').hide();
        }
    });
});

///* calling script form Xzoom */(function ($) {
